img.inactive {
    border:lightgrey solid 2px;
}

img.active {
    border: orange solid 2px;
}
header {
    overflow: hidden;
    clear: both;
}

.upload {
    float: left;
}
.profile {
    display: flex;
    align-items: center;
    padding: 5px;
    border: solid lightgrey 1px;
    border-radius: 50px 0 0 50px;
    float: right;
    margin: 5px;
}

.profile img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: lightgrey solid 1px;
}

.profile > div {
    padding: 0 10px;
}

.small {
    font-size: 10px;
}

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.list > div {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.snippets-wrapper {
    position: sticky;
    top:10px;
    display: flex;
    padding: 5px;
    background-color: #ECEFF1CC;
}
.controls {
    padding-right: 10px;
}
.controls label {
    display: inline-block;
    min-width: 70px;
    font-weight: bold;
}
textarea {
    flex: 1 1 auto;
    min-height: 100px;
}
